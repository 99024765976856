import React, { useEffect, useState } from "react";
import "./App.css";
import SignatureGenerator from "./SignatureGenerator";

async function fetchConfig() {
  const response = await fetch("config.json");
  const config = await response.json();
  return config;
}

async function fetchTemplate(type) {
  const response = await fetch(`signature.${type}`);
  const template = await response.text();
  return template;
}

const AppNew = () => {
  const [config, setConfig] = useState(null);
  const [templateHtml, setTemplateHtml] = useState(null);
  const [templateText, setTemplateText] = useState(null);

  useEffect(() => {
    (async () => {
      const configData = await fetchConfig();
      setConfig(configData);
    })();

    (async () => {
      const htmlTemplate = await fetchTemplate("html");
      setTemplateHtml(htmlTemplate);
    })();

    (async () => {
      const textTemplate = await fetchTemplate("txt");
      setTemplateText(textTemplate);
    })();
  }, []);

  if (!config || !templateHtml || !templateText) {
    return null;
  }

  return (
    <SignatureGenerator
      {...{
        config,
        templateHtml,
        templateText,
      }}
    />
  );
};

export default AppNew;
