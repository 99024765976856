import React, { Component, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Handlebars from "handlebars";
import "./App.css";

const SignatureGenerator = (props) => {
  const { config, templateHtml, templateText } = props;
  const [mode, setMode] = useState("html");
  const [textSignature, setTextSignature] = useState("");
  const [htmlSignature, setHtmlSignature] = useState("");
  const previewRef = useRef();
  const { register, handleSubmit, watch } = useForm({
    defaultValues: Object.keys(config).reduce((acc, key) => {
      acc[key] = config[key].defaultValue || "";
      return acc;
    }, {}),
  });

  const fields = watch();

  useEffect(() => {
    renderPreview(fields, false);
  });

  const renderFormField = (key) => {
    const field = config[key];

    const isEditable = field.editable !== false;

    if (!field || !isEditable) return null;

    const fieldProps = {
      ...register(key),
      placeholder: field.placeholder || "",
      required: !!field.required,
    };

    switch (field.type) {
      default:
      case "text":
        return (
          <div className="form-group row" key={key}>
            <label className="col-sm-3 col-form-label" htmlFor={key}>
              {field.label || key}
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                onBlur={() => renderPreview()}
                {...fieldProps}
              />
              {field.helpText && (
                <small className="form-text text-muted">{field.helpText}</small>
              )}
            </div>
          </div>
        );
      case "select":
        return (
          <div className="form-group row" key={key}>
            <label className="col-sm-3 col-form-label" htmlFor={key}>
              {field.label}
            </label>
            <div className="col-sm-9">
              <select className="form-control" {...fieldProps}>
                {field.options.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      case "checkbox":
        return (
          <div className="form-group row" key={key}>
            <div className="col-sm-9">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={key}
                  {...fieldProps}
                />
                <label className="form-check-label" htmlFor={key}>
                  {field.label}
                </label>
              </div>
            </div>
          </div>
        );
    }
  };

  const compileSignature = (template, data) => {
    const renderSignature = Handlebars.compile(template);
    return renderSignature(data);
  };

  const renderPreview = (data, selectAll) => {
    const previewEl = previewRef.current;

    if (!previewEl) return;

    const existingIframe = previewEl.querySelector("iframe");
    if (existingIframe) existingIframe.remove();

    let iframe = document.createElement("iframe");
    previewEl.appendChild(iframe);

    const text = compileSignature(templateText, data);
    setTextSignature(text);

    let html = compileSignature(templateHtml, data);
    setHtmlSignature(html);

    if (selectAll) {
      html =
        html +
        "<script>document.designMode = 'on'; document.execCommand('selectAll', false, null); document.designMode = 'off';</script>";
    }

    const iframeWindow = iframe.contentWindow
      ? iframe.contentWindow
      : iframe.contentDocument.document
      ? iframe.contentDocument.document
      : iframe.contentDocument;
    iframeWindow.document.open();
    iframeWindow.document.write(html);
    iframeWindow.document.close();
  };

  const selectSignature = () => {
    const previewEl = previewRef.current;
    renderPreview(fields, true);
    const previewIframe = previewEl.querySelector("iframe");
    previewIframe.focus();
  };

  const downloadSignatureSrc = (html) => {
    var blob = new Blob([html], { type: "text/html" });
    return window.URL.createObjectURL(blob);
  };

  return (
    <div className="App">
      <nav className="navbar navbar-dark bg-dark">
        <div className="navbar-brand">Email Signature Generator</div>
      </nav>
      <div className="App__main">
        <div className="App__form">
          <form
            className="needs-validation"
            onSubmit={handleSubmit((data) => renderPreview(data, false))}
            noValidate
          >
            {Object.keys(fields).map((key) => {
              return renderFormField(key);
            })}
          </form>
        </div>
        <div className="App__preview">
          <div className="btn-group">
            <button
              type="button"
              className={`btn btn-secondary ${mode === "html" ? "active" : ""}`}
              onClick={(e) => setMode("html")}
            >
              HTML
            </button>
            <button
              type="button"
              className={`btn btn-secondary ${mode === "text" ? "active" : ""}`}
              onClick={(e) => setMode("text")}
            >
              Text
            </button>
          </div>
          <div style={{ display: mode === "html" ? "block" : "none" }}>
            <div id="signatureHtml" ref={previewRef}></div>
            <hr />
            <p>
              To use this signature, click "Select Signature" below then
              Command+C/Ctrl+C on your keyboard to copy to your clipboard. You
              can then paste into the signature field of your email client.
              Alternatively, you can download the HTML source to implement in
              other ways.
            </p>
            <button
              className="btn btn-primary"
              onClick={() => selectSignature()}
            >
              Select Signature
            </button>
            &nbsp;
            {htmlSignature && (
              <a
                className="btn btn-primary"
                href={downloadSignatureSrc(htmlSignature)}
                download={`signature.html`}
              >
                Download HTML
              </a>
            )}
          </div>
          <div style={{ display: mode === "text" ? "block" : "none" }}>
            <textarea
              className="App__preview__text-preview"
              value={textSignature}
              rows="6"
              readOnly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatureGenerator;
